import { useTransactionStore } from './transactions'
import ReconciliationApi from '@/api/ReconciliationApi'
import {
  ReconciliationSetListType,
  ReconciliationSetDetailType,
  ReconciliationUnmatchedLedgerType,
  ReconciliationUnmatchedTransactionType
} from '#/ReconciliationTypes'

export const useReconciliationStore = defineStore({
  id: 'reconciliationStore',
  state: () => {
    const reconciliationState: {
      currentMatchSet: any
      reconciliationSetList: ReconciliationSetListType
      reconciliationSetDetail: ReconciliationSetDetailType | null
      unmatchedTransactions: ReconciliationUnmatchedTransactionType
      unmatchedLedgerData: ReconciliationUnmatchedLedgerType
    } = {
      currentMatchSet: {},
      reconciliationSetList: {
        totalCount: 0,
        list: []
      },
      reconciliationSetDetail: null,
      unmatchedTransactions: {
        totalCount: 0,
        list: []
      },
      unmatchedLedgerData: {
        totalCount: 0,
        list: []
      }
    }
    return reconciliationState
  },
  actions: {
    /**
     * 获取对账工作列表
     * @param {string} entityId 主体id
     * @param {Object} params 查询参数
     * @param {number} params.page 页码
     * @param {number} params.limit 每页条数
     */
    async fetchReconciliationSetList(entityId: string, params: object) {
      try {
        const { data } = await ReconciliationApi.getReconciliationSetList(entityId, params)
        this.reconciliationSetList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取对账工作详情
     * @param {string} entityId 主体id
     * @param {string} reconciliationSetId 对账工作id
     */
    async fetchReconciliationSetDetail(entityId: string, reconciliationSetId: string) {
      try {
        const { data } = await ReconciliationApi.getReconciliationSetDetail(entityId, reconciliationSetId)
        this.currentMatchSet.description = data.description
        this.reconciliationSetDetail = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取已对账列表
     * @param {string} entityId 主体id
     * @param {string} reconciliationSetId 对账工作id
     * @param {Object} params 查询参数
     * @param {number} params.page 页码
     * @param {number} params.limit 每页条数
     */
    async fetchReconciliationMatchList(entityId: string, reconciliationSetId: string, params: object) {
      try {
        const { data } = await ReconciliationApi.getReconciliationMatchList(entityId, reconciliationSetId, params)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取未对账交易列表
     * @param {string} entityId 主体id
     * @param {string} reconciliationSetId 对账工作id
     * @param {Object} params 查询参数
     * @param {number} params.page 页码
     * @param {number} params.limit 每页条数
     */
    async fetchReconciliationUnmatchedTrxList(entityId: string, reconciliationSetId: string, params: object) {
      try {
        const { data } = await ReconciliationApi.getReconciliationUnmatchedTrxList(
          entityId,
          reconciliationSetId,
          params
        )
        this.unmatchedTransactions = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取未对账分类账列表
     * @param {string} entityId 主体id
     * @param {string} reconciliationSetId 对账工作id
     * @param {Object} params 查询参数
     * @param {number} params.page 页码
     * @param {number} params.limit 每页条数
     */
    async fetchReconciliationUnmatchedLedgerList(entityId: string, reconciliationSetId: string, params: object) {
      try {
        const { data } = await ReconciliationApi.getReconciliationUnmatchedLedgerList(
          entityId,
          reconciliationSetId,
          params
        )
        this.unmatchedLedgerData = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 未对账列表数据初始化
     * @param {string} entityId 主体id
     * @param {string} reconciliationSetId 对账工作id
     * @param {Object} transactionParams 查询参数
     * @param {Object} ledgerParams 查询参数
     */
    async unmatchedListDataInit(
      entityId: string,
      reconciliationSetId: string,
      transactionParams: object,
      ledgerParams: object
    ) {
      try {
        const transactionStore = useTransactionStore()
        const response = await Promise.all([
          transactionStore.fetchTransactionCurrencyList(entityId),
          this.fetchReconciliationUnmatchedTrxList(entityId, reconciliationSetId, transactionParams),
          this.fetchReconciliationUnmatchedLedgerList(entityId, reconciliationSetId, ledgerParams)
        ])
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  getters: {},
  // 本地化持久储存
  persist: {
    enabled: true,
    strategies: [
      {
        // 默认是全部进去存储 可以选择哪些进入local存储，这样就不用全部都进去存储了
        paths: ['currentMatchSet']
      }
    ]
  }
})
